<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <span class="mr-2">CIS Application Form</span>
      </div>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>

      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            Child Part 1
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            Child Part 2
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="3"
            :complete="e1 > 3"
          >
            Child Part 3
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="4"
            :complete="e1 > 4"
          >
            Mother
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="5"
            :complete="e1 > 5"
          >
            Father
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="6"
            :complete="e1 > 6"
          >
            Documents
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="7"
            :complete="e1 > 7"
          >
            Billing
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="8"
            :complete="e1 > 8"
          >
            Declaration
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">

            <ncform
              :form-schema="fs_child1"
              form-name="fs_child1"
              v-model="fs_child1.value"
              @submit="submit()"
            ></ncform>
            <br><br>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 2"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">

            <h4>Health and Safety</h4>

            <ncform
              :form-schema="fs_child2"
              form-name="fs_child2"
              v-model="fs_child2.value"
              @submit="submit()"
            ></ncform>
            <br><br>

            <v-btn
              @click="e1 = 1"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 3"
            >
              Next
            </v-btn>

          </v-stepper-content>

          <v-stepper-content step="3">
            <ncform
              :form-schema="fs_child3"
              form-name="fs_child3"
              v-model="fs_child3.value"
              @submit="submit()"
            ></ncform>
            <br><br>

            <v-btn
              @click="e1 = 2"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 4"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <h3>Mother's Details</h3>
            <ncform
              :form-schema="fs_mother"
              form-name="fs_mother"
              v-model="fs_mother.value"
              @submit="submit()"
            ></ncform>
            <br><br>

            <v-btn
              @click="e1 = 3"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 5"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="5">
            <h3>Father's Details</h3>
            <ncform
              :form-schema="fs_father"
              form-name="fs_father"
              v-model="fs_father.value"
              @submit="submit()"
            ></ncform>
            <br><br>

            <v-btn
              @click="e1 = 4"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 6"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="6">
            <h3>Documents</h3>

            <p>The following documents, which must all be original versions, should be uploaded with this application. Alternatively, you can email them to our Admissions Department. </p>

            <p>*These documents must be supplied in English. If documents are not in English, parents will need to provide official translated copies. Translations must be carried out by a Notary Public, a Translation Agency or your own National Embassy.</p>

            <ncform
              :form-schema="fs_documents"
              form-name="fs_documents"
              v-model="fs_documents.value"
              @submit="submit()"
            ></ncform>

            <br><br>

            <v-btn
              @click="e1 = 5"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 7"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="7">
            <h3>Billing</h3>

            <ncform
              :form-schema="fs_billing"
              form-name="fs_billing"
              v-model="fs_billing.value"
              @submit="submit()"
            ></ncform>

            <br><br>

            <v-btn
              @click="e1 = 6"
              text
            >
              Previous
            </v-btn>

            <v-btn
              @click="savedraft()"
              text
            >
              Save as Draft
            </v-btn>

            <v-btn
              color="primary"
              @click="e1 = 8"
            >
              Next
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="8">
            <h3>Declaration</h3>

            <p><strong>Terms &amp; agreement</strong></p>
            <p><strong>Acknowledgement, Acceptance and Indemnification</strong></p>
            <p>&nbsp;I, the undersigned Applicant, request the enrolment of my Child and confirm that I understand and accept all the terms and conditions governing the enrolment of my Child into CIS. I further understand that I will be required to read, understand and sign a copy of the Student Contract upon acceptance. I will also be given an e-copy of the Parent Handbook, which will provide me with further information about the School.&nbsp;</p>
            <p><strong>Accuracy of Information</strong></p>
            <p>I certify that all particulars furnished in this application and any accompanying documentation are true, accurate, complete, original and authentic. I acknowledge that withholding information relating to my Child, including but not limited to the Child&rsquo;s immigration status, medical, educational and/or emotional needs, may affect my Child being offered, or maintaining, a place at CIS. I undertake to notify the school promptly of any changes in the particulars in this application or of my intention to withdraw it.</p>
            <p>I warrant and declare that all information regarding support programmes, special needs and/or all other learning and behavioural issues of my Child were declared in this application and made known to CIS. The School reserves the right to request private assessments, such as psycho educational and/or occupational therapy, ADHD identification and visual or auditory assessments, as well as the right to request additional Learning Support services for the Child, if such a programme is required to ensure success at the School. All placement decisions are made by the School.</p>
            <p><strong>Personal Data Protection Act</strong></p>
            <p>In submitting my personal data to you in this application, I acknowledge and consent to your collection, use, process or disclosure of my/my child&rsquo;s personal data for the purposes reasonably required in connection with your provision of services, including but not limited to those purposes specified in your <a href="https://www.cis.edu.sg/pdpa">Personal Data Protection Policy</a>, the terms of which are hereby incorporated by reference.</p>
            <p>I also consent to the disclosure of my /my child&rsquo;s personal data to your third party service providers, agents, affiliated companies and/or other third parties, whether within or outside Singapore, for one or more of the above mentioned purposes.</p>
            <p>Where I have provided personal data relating to other individuals (including minors), I represent and warrant that I am authorised to provide their personal data to you and have obtained their consent to the collection, use, processing and disclosure of their personal data in accordance with the purposes reasonably required in connection with your provision of services.</p>
            <p>If you have any questions relating to our Personal Data Protection Policy or would like to withdraw your consent to use of your data, please contact our Data Protection Officer at pdpa@cis.edu.sg.</p>
            <p><br /><br /><br /><br /><br /></p>
            <p>By submitting this application, I warrant that I am the Parent or Legal Guardian of the Child and that I am able to act on behalf of both Parents (if a parent is the Applicant) and all Legal Guardians (if a Legal Guardian is the Applicant) of the Child; and hereby indemnify the School in respect of any breach of any of the above representations.</p>

            <br><br>

            <v-btn
              @click="e1 = 7"
              text
            >
              Previous
            </v-btn>

            <v-btn
              color="primary"
              @click="submitapplication()"
            >
              Agree and Submit
            </v-btn>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  components: {
  },
  methods: {
    savedraft: function () {

      //console.log(this.fs_child1.value);
    },
    submitapplication: function () {

    }
  },
  data: () => ({
    e1: 1,
    valid: false,
    fs_billing: {
      type: 'object',
      properties: {
        billing_paidby: {
          "type": "string",
          "ui": {
            label: "Fees will be paid by",
            "widget": "select",
            "widgetConfig": {
              "filterable": true,
              "enumSource": [
                {
                  "value": "Employer",
                  "label": "Employer"
                },
                {
                  "value": "Parents",
                  "label": "Parents"
                },
                {
                  "value": "Unsure",
                  "label": "Unsure"
                }
              ]
            }
          }
        },
        billing_company: {
          "type": "string",
          "ui": {
            "label": "Company to be billed",
            "hidden": "dx: !{{$root.billing_paidby}} || {{$root.billing_paidby}} !='Employer'",
          },
        },
        billing_attention: {
          "type": "string",
          "ui": {
            "label": "Attention to",
            "hidden": "dx: !{{$root.billing_paidby}} || {{$root.billing_paidby}} == 'Unsure'",
          },
        },
        billing_address: {
          "type": "string",
          "ui": {
            "widget": "textarea",
            "label": "Billing Address",
            "hidden": "dx: !{{$root.billing_paidby}} || {{$root.billing_paidby}} == 'Unsure'",
          },
        },
        billing_email: {
          "type": "string",
          "ui": {
            "label": "Email",
            "hidden": "dx: !{{$root.billing_paidby}} || {{$root.billing_paidby}} == 'Unsure'",
          },
        },

      }

    },
    fs_documents: {
      type: 'object',
      properties: {
        "passport_photograph": {
          "type": "array",
          "ui": {
            "label": "3 passport size photographs",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "transcripts": {
          "type": "array",
          "ui": {
            "label": "The last 3 years of transcripts, school reports, testimonials and recommendations",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "reports": {
          "type": "array",
          "ui": {
            "label": "Photocopy of all relevant psycho educational or special education evaluation reports* (if applicable)",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "student_passport": {
          "type": "array",
          "ui": {
            "label": "Photocopy of Student's Passport",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "student_birth_cert": {
          "type": "array",
          "ui": {
            "label": "Photocopy of Student's Birth Certificate",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "student_immunisation": {
          "type": "array",
          "ui": {
            "label": "Photocopy of Student's immunisation records. Diphtheria and Measles immunisation is compulsory by law in Singapore.",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
        "parents_passport": {
          "type": "array",
          "ui": {
            "label": "Each parent’s passport and/or each legal guardian’s passport (where applicable)",
            "widget": "upload",
            "widgetConfig": {
              "uploadUrl": "/api/upload/getFileInfo",
              "drag": true
            }
          }
        },
      }
    },
    fs_father: {
      type: 'object',
      properties: {
        father_first_name: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "First Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        father_last_name: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Last Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        father_nationality: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Nationality",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "demonym",
              "itemValueField": "demonym",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=demonym;"
              }
            }
          }
        },
        father_resident_country: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Current country of residence",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "name",
              "itemValueField": "name",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=name;"
              }
            }
          }
        },
        father_email: {
          "type": "string",
          "ui": {
            "label": "Email",
            "description": "Will be used for school communications. Yahoo emails are not recommended",
          },
          "rules": {
            "required": true,
          }
        },
        father_wechatid: {
          "type": "string",
          "ui": {
            "label": "WeChat ID (if applicable)",
          }
        },

        father_job: {
          "type": "string",
          "ui": {
            "label": "Job title",
          }
        },

        father_employer: {
          "type": "string",
          "ui": {
            "label": "Employer",
            "description": "indicate N/A if not applicable",
          }
        },

      }
    },

    fs_mother: {
      type: 'object',
      properties: {
        mother_first_name: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "First Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        mother_last_name: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Last Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        mother_nationality: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Nationality",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "demonym",
              "itemValueField": "demonym",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=demonym;"
              }
            }
          }
        },
        mother_resident_country: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Current country of residence",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "name",
              "itemValueField": "name",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=name;"
              }
            }
          }
        },
        mother_email: {
          "type": "string",
          "ui": {
            "label": "Email",
            "description": "Will be used for school communications. Yahoo emails are not recommended",
          },
          "rules": {
            "required": true,
          }
        },
        mother_wechatid: {
          "type": "string",
          "ui": {
            "label": "WeChat ID (if applicable)",
          }
        },

        mother_job: {
          "type": "string",
          "ui": {
            "label": "Job title",
          }
        },

        mother_employer: {
          "type": "string",
          "ui": {
            "label": "Employer",
            "description": "indicate N/A if not applicable",
          }
        },

      }
    },

    fs_child3: {
      type: 'object',
      properties: {
        label: {
          "type": "string",
          "value": "Further insights about your child",
          "ui": {
            noLabelSpace: true,
            "widget": "label",
            "widgetConfig": {
              "inVLayout": true
            }
          }
        },
        c3_birth_complications: {
          "type": "boolean",
          "ui": {
            "label": "Were there any complications with your child's birth?",
          },

        },
        c3_birth_complications_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_birth_complications}}",
            "label": "Please provide details",
          },
        },
        c3_health_issues: {
          "type": "boolean",
          "ui": {
            "label": "Were there any significant health issues in your child's first years of life?",
          },

        },
        c3_health_issues_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_health_issues}}",
            "label": "Please provide details",
          },
        },
        c3_development_concerns: {
          "type": "boolean",
          "ui": {
            "label": "Have there been any areas of concern with your child's development to date?",
          },

        },
        c3_development_concerns_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_development_concerns}}",
            "label": "Please provide details",
          },
        },
        c3_coordination_difficulty: {
          "type": "boolean",
          "ui": {
            "label": "Does/ Did your child have difficulty with general coordination, balance and physical skills?",
          },

        },
        c3_coordination_difficulty_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_coordination_difficulty}}",
            "label": "Please provide details",
          },
        },
        c3_attention_difficulty: {
          "type": "boolean",
          "ui": {
            "label": "Has your child ever had difficulty with attention/concentration?",
          },

        },
        c3_attention_difficulty_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_attention_difficulty}}",
            "label": "Please provide details",
          },
        },
        c3_toilet_trained: {
          "type": "boolean",
          "ui": {
            "label": "Is your child toilet trained?",
          },

        },
        c3_toilet_trained_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_attention_difficulty}}",
            "label": "Please provide details",
          },
        },

        "label2": {
          "type": "string",
          "default": "Your child's details",
          "ui": {
            noLabelSpace: true,
            "widget": "label",
            "widgetConfig": {
              "inVLayout": true
            }
          }
        },

        c3_learning_difficulties: {
          "type": "boolean",
          "ui": {
            "label": "Been assessed with learning difficulties?",
          },

        },
        c3_learning_difficulties_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_learning_difficulties}}",
            "label": "Nature of the difficulty",
          },
        },

        c3_academic_support: {
          "type": "boolean",
          "ui": {
            "label": "Benefited from academic/ learning support?",
          },

        },
        c3_academic_support_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_academic_support}}",
            "label": "Nature of support or remedial help",
          },
        },

        c3_gifted: {
          "type": "boolean",
          "ui": {
            "label": "Been assessed as gifted/talented?",
          },

        },
        c3_gifted_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_gifted}}",
            "label": "Please provide details",
          },
        },

        c3_specialist_support: {
          "type": "boolean",
          "ui": {
            "label": "Been supported by a specialist (i.e. psychiatrist, psychologist, speech pathologist, school counsellor / community counsellor, occupational therapist)?",
          },

        },
        c3_specialist_support_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_specialist_support}}",
            "label": "Please provide details",
          },
        },

        c3_physical_difficulties: {
          "type": "boolean",
          "ui": {
            "label": "Had any hearing, speech or physical difficulties?",
          },

        },
        c3_physical_difficulties_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_physical_difficulties}}",
            "label": "Please provide details",
          },
        },

        c3_social_difficulties: {
          "type": "boolean",
          "ui": {
            "label": "Does your child have any social, emotional and/or behavioural difficulties?",
          },

        },
        c3_social_difficulties_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c3_social_difficulties}}",
            "label": "Please provide details",
          },
        },


      }
    },
    fs_child2: {
      type: 'object',
      properties: {
        "label": {
          "type": "string",
          "default": "Does your child",
          "ui": {
            noLabelSpace: true,
            "widget": "label",
            "widgetConfig": {
              "inVLayout": true
            }
          }
        },
        c2_medical_condition: {
          "type": "boolean",
          "ui": {
            "label": "Have any medical conditions that may impact their participation in the classroom or in sports?",
          },

        },
        c2_medical_condition_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c2_medical_condition}}",
            "label": "Please state the nature of the condition(s) and submit all medical reports",
          },
        },
        c2_medical_allergies: {
          "type": "boolean",
          "ui": {
            "label": "Have any allergies that may impact his/her participation in classroom or in sports?",
          },

        },
        c2_medical_allergies_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c2_medical_allergies}}",
            "label": "Please state the nature of the allergies",
          },
        },
        c2_medical_medication: {
          "type": "boolean",
          "ui": {
            "label": "Require any medication?",
          },

        },
        c2_medical_medication_desc: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.c2_medical_medication}}",
            "label": "Please include the details of the medication",
          },
        },
      }
    },
    fs_child1: {
      type: 'object',
      properties: {
        "label": {
          "type": "string",
          "default": "Your child's details",
          "ui": {
            noLabelSpace: true,
            "widget": "label",
            "widgetConfig": {
              "inVLayout": true
            }
          }
        },
        custentity_cis_first_name: {
          "type": "string",
          "ui": {
            "columns": 4,
            "label": "First Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        custentity_cis_last_name: {
          "type": "string",
          "ui": {
            "columns": 4,
            "label": "Last Name",
            "description": "as in passport",
          },
          "rules": {
            "required": true,
          }
        },
        custentity_cis_middle_name: {
          "type": "string",
          "ui": {
            "columns": 4,
            "label": "Middle Name/s",
            "description": "as in passport",
          }
        },


        custentity_cis_preferred_name: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Preferred name",
          },
          "rules": {
            "required": true,
          }
        },
        custentity_ar_gender: {
          "type": "string",
          "ui": {

            "label": "Gender",
            "widget": "radio",
            "widgetConfig": {
              "enumSource": [
                {
                  "value": "Male",
                  "label": "Male"
                },
                {
                  "value": "Female",
                  "label": "Female"
                }
              ]
            }
          }
        },
        custentity_ar_date_of_birth: {
          "type": "string",
          "ui": {
            "label": "Date of Birth",
            "widget": "date-picker"
          }
        },
        custentity_cis_country_of_birth: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Country of Birth",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "name",
              "itemValueField": "name",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=name;"
              }
            }
          }
        },
        custentity_cis_nationality_1_new: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Nationality",
            "widget": "select",
            "widgetConfig": {
              "itemLabelField": "demonym",
              "itemValueField": "demonym",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=demonym;"
              }
            }
          }
        },
        custentity_cis_passport_1: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Passport Number",
          },
          "rules": {
            "required": true,
          }
        },
        custentity_cis_expiry_date_1: {
          "type": "string",
          "ui": {
            "columns": 6,
            "label": "Passport expiry date",
            "widget": "date-picker"
          }
        },

        custentity_cis_dual_citizenship: {
          "type": "boolean",
          "ui": {
            "label": "Dual citizenship?",
            "help": {
              "show": true,
              "text": "🛈",
              "content": "Dual nationality or citizenship means that a person is a national of two countries at the same time. Each country has its own nationality laws based on its own policy."
            }
          },

        },

        custentity_cis_nationality_2: {
          "type": "string",
          "ui": {
            "hidden": "dx: !{{$root.custentity_cis_dual_citizenship}}",
            "columns": 6,
            "label": "Dual citizenship countries",
            "widget": "select",
            "widgetConfig": {
              "multiple": true,
              "itemLabelField": "name",
              "itemValueField": "name",
              "filterable": true,
              "enumSourceRemote": {
                "remoteUrl": "https://restcountries.eu/rest/v2/all?fields=name;"
              }
            }
          }
        },


      }
    }
  }),
};
</script>

<style scoped>
</style>