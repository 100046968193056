import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import vueNcform from '@ncform/ncform';

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import ncformStdComps from '@ncform/ncform-theme-elementui';
import axios from 'axios';

Vue.use(Element, { locale });
Vue.use(vueNcform, { extComponents: ncformStdComps, lang: 'en' });
window.$http = Vue.prototype.$http = axios;

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
